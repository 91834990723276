<template>
  <b-card>
    <b-form>
      <b-tabs
        v-model="tabIndex"
        :align="align"
      >
        <slot name="header" />
        <b-tab
          v-for="(tab, index) in languageList"
          :key="index"
          :ref="`tab`"
          :title="tab.abbreviation"
          :disabled="isDisabled(index)"
          :lazy="index !== 0 && isAddMode"
        >
          <template #title>
            <span>{{ tab.name }}</span>
            <span
              v-if="isTabDirty(index)"
              v-b-tooltip.hover.righttop="'You have validation errors!'"
              class="validation-icon"
              variant="primary"
            >
              <feather-icon
                icon="AlertCircleIcon"
                size="20"
              />
            </span>
            <span
              v-if="isDisabled(index)"
              v-b-tooltip.hover.html.righttop="
                `<div class='text-left'>${tab.name} translation option is disabled.<br/>Please enter and save English before adding translations.</div>`
              "
              class="disabled-icon"
              variant="primary"
            >
              <feather-icon
                icon="InfoIcon"
                size="20"
              />
            </span>
          </template>
          <slot
            :index="index"
            :localeId="tab.id"
            :locales="locales"
          />
        </b-tab>
        <slot name="footer" />
      </b-tabs>
    </b-form>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BCard,
  BForm,
  VBModal,
  BTab,
  BTabs,
  VBTooltip,
} from "bootstrap-vue";
import { required } from "@validations";
import { getValidationState } from "@/libs/utils";
import { computed } from "@vue/composition-api";
import store from "@/store";
import { locales } from "@models";

export default {
  name: "AomLanguagesComponent",
  components: {
    BCard,
    BForm,
    BTab,
    BTabs
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },
  props: {
    maxLanguagesToShow: {
      type: Number,
      default: 5,
    },
    dirtyTabs: {
      type: Array,
      default: () => [],
    },
    isAddMode: {
      type: Boolean,
    },
    useProgramLanguages: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      required,
      selectedLanguage: undefined,
      tabIndex: 0,
      selectedLocaleId: undefined,
      dir: null,
      lockTabIndex: undefined,
    };
  },

  computed: {
    ...mapGetters("app", ["languages", "locales", "isChampionPortal"]),
    ...mapGetters("programs", ["programDefaultLangs"]),
    languageList() {
      if (this.useProgramLanguages) {
        return this.programDefaultLangs;
      }
      return this.isChampionPortal && this.programDefaultLangs.length
        ? this.programDefaultLangs
        : this.languages;
    },
  },
  watch: {
    tabIndex: {
      handler(newValue) {
        if (this.languages.length > 0) {
          this.selectedLanguage = this.languages[newValue];
          this.selectedLocaleId = this.selectedLanguage?.id || locales.EN;
        }
      },
      immediate: true,
    },
    lockTabIndex(n) {
      this.isDisabled(n);
    },
  },
  beforeCreate() {
    store.dispatch("app/RELOAD_APP_DATA");
  },
  provide() {
    return {
      localeId: computed(() => this.selectedLocaleId),
      currentTab: computed(() => this.tabIndex),
    };
  },
  methods: {
    isTabDirty(index) {
      if (this.dirtyTabs) {
        return this.dirtyTabs.includes(`${index}`);
      }
      return false;
    },
    isDisabled(index) {
      if (index !== 0 && this.isAddMode) {
        // disable all but first tab
        return true;
      }
    },
  },
  setup() {
    return {
      getValidationState,
    };
  },
};
</script>
<style lang="scss" scoped>
.validation-icon {
  margin-left: 5px;
  color: red;
}
.card {
  box-shadow: none;
}
.disabled-icon {
  margin-left: 5px;
}
</style>

<style lang="scss">
.nav-tabs {
  .nav-link.disabled {
    pointer-events: auto;
  }
}
</style>
