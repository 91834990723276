export const AOM_TEXT_EDITOR_TOOLBAR = 'aommergetags | undo redo | formatselect | bold italic backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | link image | help';

export const AOM_TEXT_EDITOR_TOOLBAR_WITHOUT_MERGE_TAGS = 'undo redo | formatselect | bold italic backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | link image | help';

export const AOM_TEXT_EDITOR_TOOLBAR_FOR_RESOURCES = 'undo redo | formatselect | bold italic forecolor backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | table | removeformat | link image | help';